






































































import { computed, defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'Links',
  // props: {
  // },
  components: {},
  setup: () => {
    const links = ref([
      {
        name: 'Feuerwehr Ostbevern',
        url: 'http://www.feuerwehr-ostbevern.de/'
      },
      {
        name: 'DRK',
        url: 'https://www.drk.de/'
      },
      {
        name: 'Feuerwehr Ahlen',
        url: 'https://www.feuerwehr-ahlen.de/'
      },
      {
        name: 'Feuerwehr Beckum',
        url: 'https://feuerwehr-beckum.chayns.net/'
      },
      {
        name: 'Feuerwehr Beelen',
        url: 'https://feuerwehr-beelen.de/'
      },
      {
        name: 'Feuerwehr Drensteinfurt',
        url: 'http://www.feuerwehr-drensteinfurt.de/'
      },
      {
        name: 'Feuerwehr Ennigerloh',
        url: 'http://www.feuerwehr-ennigerloh.de/'
      },
      {
        name: 'Feuerwehr Sassenberg',
        url: 'https://feuerwehr-sassenberg.de/'
      },
      {
        name: 'Feuerwehr Oelde',
        url: 'https://www.feuerwehr-oelde.de/de/'
      },
      {
        name: 'Feuerwehr Sendenhorst',
        url: 'https://www.feuerwehr-sendenhorst.de/index.php/de/'
      },
      {
        name: 'Feuerwehr Telgte',
        url: 'https://www.feuerwehr-telgte.de/startseite'
      },
      {
        name: 'Feuerwehr Wadersloh',
        url: 'https://www.feuerwehr-wadersloh.de/startseite.html'
      },
      {
        name: 'Feuerwehr Warendorf',
        url: 'http://www.feuerwehr-warendorf.de/'
      },
      {
        name: 'Stadt- und Feuerwehrkapelle',
        url: 'http://www.stadt-undfeuerwehrkapelle.de/cms/'
      },
      {
        name: 'Sicherheitserziehung NRW',
        url: 'https://www.sicherheitserziehung.de/'
      },
      // {
      //   name: 'Rauchmelder für NRW',
      //   url: 'https://www.sicherheitserziehung.de/'
      // },
      {
        name: 'DRK Everswinkel',
        url: 'http://www.drk-everswinkel.de/'
      },
      {
        name: 'Kreisfeuerwehrverband Warendorf',
        url: 'https://www.kfv-warendorf.de/'
      },
      {
        name: 'THW Ortsverband Warendorf',
        url: 'https://ov-warendorf.thw.de/'
      }
    ]);

    const listMode = ref(true);
    const gridCount = 3;

    const gridLinks = computed(() => {
      // sliding window
      const grid = [];
      const linksCount = links.value.length;
      for (let i = 0; i < linksCount; ) {
        const currLinks = [];
        for (let j = 0; j < gridCount && i < linksCount; ++i, ++j) {
          currLinks.push(links.value[i]);
        }
        grid.push(currLinks);
      }

      return grid;
    });

    return {
      links,
      listMode,
      gridLinks
    };
  }
});
